import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import axios from 'axios'
import Qs from 'qs'
// import {Pagination, PaginationEvent} from 'vue-pagination-2'
import Cookies from 'cookies-js'
import S from 'string'
import { createBrowserHistory } from 'history';
import $ from 'jquery'
import ready from 'document-ready'

ready(() => {
  if (!document.getElementById('vue-products')) { return }

  const history = createBrowserHistory()

  let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  window.qs = Qs.parse(decodeURI(window.location.search.substr(1)))

  for (let prop in qs) {
    qs[prop] = qs[prop].split(',')
  }

  let facets = __FACETS__.map(facet => {
    facet.options = facet.options.map(option => {
      let facet_url_param = facet.title.toLowerCase().replace(/\s/g, '_')
      let facet_param = qs[facet_url_param]
      let checked = false
      if (facet_param !== undefined && facet_param.indexOf(option) >= 0) {
        checked = true
      }
      return { title: option, checked }
    })
    facet.options_count = facet.options.length
    facet.expanded = facet.options.length <= 5
    return facet
  })

  window.productsVmState = {
    pagination: {
      currentPage: 1,
      lastPage: 1,
      nextPageUrl: null,
      perPage: 12,
      prevPageUrl: null,
      total: 12
    },
    loading: true,
    loadingPage: false,
    facets: facets,
    wishlist: __WISHLIST__,
    products: [],
    q: qs["q"],
    qs: qs,
    unit: Cookies.get('braiform.unit') || 'cms',
    locale: locale(),
    facetsIsOpen: false
  }

  Vue.component('products-facet-option', {
    props: ['facet','option','index'],
    computed: {
      visible: function () {
        if (this.facet.expanded) return true
        return (this.index < 5)
      }
    },
    methods: {
      getProducts() {
        window.productsListVm.$emit('getProducts')
      }
    },
    template: `<label v-show="visible">
      <input type="checkbox" v-model="option.checked" v-on:change="getProducts()">
      {{ option.title }}
    </label>`
  })

  Vue.component('products-facet', {
    props: ['facet'],
    computed: {
      isExpandable: function () {
        return this.facet.options_count > 5
      },
      canClear: function () {
        return this.facet.options.findIndex(o => o.checked == true) !== -1
      }
    },
    methods: {
      toggleExpanded: function () {
        this.facet.expanded = !this.facet.expanded
      },
      clear: function () {
        this.facet.options.map(option => {
          option.checked = false
          return option
        })
        window.productsListVm.$emit('getProducts')
      }
    },
    template: `<div class="bc-products-facet">
      <span class="bc-products-facet__title">{{ facet.title }}</span>
      <products-facet-option
        v-for="(option, index) in facet.options"
        v-bind:option="option"
        v-bind:facet="facet"
        v-bind:index="index"
        v-bind:key="index"
      ></products-facet-option>
      <div class="bc-products-facets__buttons">
        <button type="button" v-on:click="toggleExpanded" v-show="isExpandable">
          <template v-if="facet.expanded">See less</template>
          <template v-else>See all</template>
        </button>
        <button class="danger" type="button" v-on:click="clear" v-show="canClear">Clear</button>
      </div>
    </div>`
  })

  Vue.component('products-facets', {
    props: ['facets', 'isOpen'],
    data () {
      return {
        productsVmState: productsVmState
      }
    },
    methods: {
      clear: function () {
        productsListVm.clearFacets()
        productsListVm.clearSearch()
        productsListVm.getProducts()
      },
      clearSearch: function () {
        productsListVm.clearSearch()
      }
    },
    computed: {
      filtered: function () {
        let filtered = false;
        for (let facet of this.facets) {
          for (let option of facet.options) {
            if (option.checked) {
              filtered = true
            }
          }
        }
        return filtered
      }
    },
    template: `<div class="bc-products-facets" v-bind:class="{ 'is-open': isOpen }">
      <div class="bc-products-facet" v-if="productsVmState.q">
        <span class="bc-products-facet__title">Search Results</span>
        <div class="bc-products-facet__main">
          <span>{{ productsVmState.pagination.total }} {{ productsVmState.pagination.total === 1 ? 'result' : 'results' }} for '{{ productsVmState.q.toString() }}'</span>
        </div>
        <button type="button" class="danger" @click="clearSearch">Clear</button>
      </div>
      <products-facet
        v-for="facet in facets"
        v-bind:facet="facet"
        v-bind:key="facet.key"
      ></products-facet>
      <button class="bc-button bc-button--neutral" type="button" v-on:click="clear" v-show="filtered">
        Clear filters
        <svg width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg"><path d="M6 5.39L1.836 1.225C1.412.8.732.8.314 1.22c-.422.42-.417 1.1.005 1.52l4.164 4.166L.32 11.07c-.423.423-.428 1.102-.006 1.523.418.42 1.098.418 1.522-.005L6 8.423l4.164 4.165c.424.423 1.104.424 1.522.005.422-.42.417-1.1-.005-1.522L7.517 6.907 11.68 2.74c.423-.42.428-1.1.006-1.52-.418-.42-1.098-.42-1.522.005L6 5.39z" fill="#FFF" fill-rule="evenodd"/></svg>
      </button>
    </div>`
  })

  Vue.component('products-list-product', {
    props: ['product'],
    data: function () {
      return {
        added: false,
        addedTimeout: null
      }
    },
    mounted: function () {
      imgix.init()
      lazySizes.init()
    },
    methods: {
      toggleInWishlist: function () {
        let action = `/my-catalogue?group=${this.product.group}`

        if (this.inWishlist) {
          axios.delete(action)
          .then((response) => {
            this.added = false
            productsVmState.wishlist = response.data.wishlist
            window.updateWishlistCount(response.data.wishlist)
          }).catch(function (error) {
            alert(error)
          })
        } else {
          axios.patch(action)
          .then((response) => {
            this.added = true
            if (this.addedTimeout) { clearTimeout(this.addedTimeout) }
            this.addedTimeout = setTimeout(() => {
              this.added = false
            }, 1000)
            productsVmState.wishlist = response.data.wishlist
            window.updateWishlistCount(response.data.wishlist)
          }).catch(function (error) {
            alert(error)
          })
        }
      },
      visit: function () {
        window.location = this.url
      }
    },
    computed: {
      firstFeature: function () {
        let features = this.product.features
        if (features.length) {
          return features[0]['description']
        } else {
          return null
        }
      },
      localizedFirstFeature: function () {
        let locale = productsVmState.locale
        let localeFeatures = this.product.features.filter(feature => {
          return feature.locale === null || feature.locale == locale
        })
        if (localeFeatures.length) {
          return localeFeatures[0]['description']
        } else {
          return null
        }
      },
      buttonClass: function () {
        if (this.added) {
          return '-added'
        } else if (this.inWishlist) {
          return '-remove'
        } else {
          return ''
        }
      },
      buttonText: function () {
        if (this.added) {
          return 'Added'
        } else if (this.inWishlist) {
          return 'Remove'
        } else {
          return 'My Catalogue'
        }
      },
      inWishlist: function () {
        return productsVmState.wishlist.products.findIndex(p => p.id == this.product.id) !== -1
      },
      unitCm: function () {
        return productsVmState.unit === 'cms'
      },
      unitInches: function () {
        return productsVmState.unit === 'inches'
      },
      url: function () {
        return `/catalogue/${this.product.group}`
      }
    },
    template: `<div @click="visit" class="bc-products-list__product bc-product-card">
      <div class="bc-product-card__image">
        <img class="lazyload" v-bind:ix-src="product.image" sizes="(min-width: 1024px) 25vw, (min-width: 780px) 50vw, 100vw" alt="" width="700" height="495">
      </div>
      <div class="bc-product-card__info">
        <span class="bc-product-card__group">{{ product.group }}</span>
        <!--<span v-if="localizedFirstFeature" class="bc-product-card__feature">{{ localizedFirstFeature }}</span>-->
        <span v-if="product.description" class="bc-product-card__feature">{{ product.description }}</span>
        <span v-if="unitCm" class="bc-product-card__sizes" data-sizes="cms">{{ product.sizesCm }}</span>
        <span v-if="unitInches" class="bc-product-card__sizes" data-sizes="inches">{{ product.sizesInch }}</span>
      </div>
      <button class="bc-product-card__button" :class="buttonClass" v-on:click.stop.prevent="toggleInWishlist" type="button">
        <svg v-if="added" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M8.493 15.442c3.838 0 6.95-3.11 6.95-6.95 0-3.837-3.112-6.948-6.95-6.948s-6.95 3.11-6.95 6.95c0 3.837 3.112 6.948 6.95 6.948zm0 1.544C3.803 16.986 0 13.184 0 8.493 0 3.803 3.803 0 8.493 0s8.493 3.803 8.493 8.493-3.802 8.493-8.493 8.493zm-2.26-6.392L4.96 9.337c-.222-.22-.593-.22-.82.004-.23.23-.23.59-.004.815l1.657 1.637.002.003.025.025c.227.225.597.225.827-.003l5.355-5.292c.23-.226.232-.59.003-.818-.228-.225-.598-.224-.828.003l-4.944 4.887z" fill="#72BA3A"/></svg>
        <svg v-else-if="inWishlist" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M8.46 15.383c3.824 0 6.923-3.1 6.923-6.922 0-3.82-3.1-6.92-6.922-6.92-3.82 0-6.92 3.1-6.92 6.924 0 3.824 3.1 6.923 6.924 6.923zm0 1.538C3.79 16.92 0 13.137 0 8.46 0 3.79 3.788 0 8.46 0c4.673 0 8.46 3.788 8.46 8.46 0 4.673-3.787 8.46-8.46 8.46zM6.15 7.696c-.417 0-.766.344-.766.77 0 .426.343.768.767.768h4.62c.42 0 .77-.344.77-.77 0-.426-.343-.767-.767-.767H6.15z" fill="#D65466"/></svg>
        <svg v-else width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M8.493 15.442c3.838 0 6.95-3.11 6.95-6.95 0-3.837-3.112-6.948-6.95-6.948s-6.95 3.11-6.95 6.95c0 3.837 3.112 6.948 6.95 6.948zm0 1.544C3.803 16.986 0 13.184 0 8.493 0 3.803 3.803 0 8.493 0s8.493 3.803 8.493 8.493-3.802 8.493-8.493 8.493zm.772-9.265V6.178c0-.42-.345-.77-.772-.77-.43 0-.772.344-.772.77V7.72H6.178c-.42 0-.77.347-.77.773 0 .43.344.772.77.772H7.72v1.547c0 .42.347.77.773.77.43 0 .772-.345.772-.77V9.265h1.547c.42 0 .77-.345.77-.772 0-.43-.345-.772-.77-.772H9.265z" fill="#273455"/></svg>
        {{ buttonText }}
      </button>
    </div>`
  })

  // Vue.component('products-list-pagination', {
  //   data: function () {
  //     return productsVmState
  //   },
  //   computed: {
  //     from: function () {
  //       if (this.pagination.currentPage == 1) return 1
  //       return (this.pagination.currentPage - 1) * this.pagination.perPage
  //     },
  //     to: function () {
  //       if (this.pagination.currentPage == 1) return this.paginationSize
  //       return this.from + this.paginationSize
  //     },
  //     paginationSize: function () {
  //       return this.pagination.size
  //     },
  //     paginationTotal: function () {
  //       return this.pagination.total
  //     },
  //     firstPage: function() {
  //       return this.pagination.currentPage === 1
  //     },
  //     lastPage: function() {
  //       return this.pagination.currentPage === this.pagination.lastPage;
  //     }
  //   },
  //   methods: {
  //     prev: function () {
  //       if (this.firstPage) return
  //       let page = this.pagination.currentPage;
  //       page--;
  //       window.productsListVm.$emit('pageProducts', page)
  //     },
  //     next: function () {
  //       if (this.lastPage) return
  //       let page = this.pagination.currentPage;
  //       page++;
  //       window.productsListVm.$emit('pageProducts', page)
  //     }
  //   },
  //   template: `<div class="bc-products-list-pagination">
  //     <span v-if="paginationSize">
  //       {{ from }}-{{ to }} of {{ paginationTotal }}
  //       <button type="button" v-show="!firstPage" @click="prev">Previous</button>
  //       <button type="button" v-show="!lastPage" @click="next">Next</button>
  //     </span>
  //     <span v-else>
  //       No results
  //     </span>
  //   </div>`
  // })

  Vue.component('products-list-pagination', {
    data: function () {
      return productsVmState
    },
    computed: {
      from: function () {
        if (this.pagination.currentPage == 1) return 1
        return (this.pagination.currentPage - 1) * this.pagination.perPage
      },
      to: function () {
        if (this.pagination.currentPage == 1) return this.paginationSize
        return this.from + this.paginationSize
      },
      paginationSize: function () {
        return this.pagination.size
      },
      paginationTotal: function () {
        return this.pagination.total
      },
      firstPage: function() {
        return this.pagination.currentPage === 1
      },
      lastPage: function() {
        return this.pagination.currentPage === this.pagination.lastPage;
      },
      showPagination () {
        return this.paginationSize && !this.lastPage
      },
      buttonText () {
        return this.loadingPage ? 'Loading…' : 'Load more'
      }
    },
    methods: {
      prev: function () {
        if (this.firstPage) return
        let page = this.pagination.currentPage;
        page--;
        window.productsListVm.$emit('pageProducts', page)
      },
      next: function () {
        if (this.lastPage) return
        let page = this.pagination.currentPage;
        page++;
        window.productsListVm.$emit('pageProducts', page)
      }
    },
    template: `<div class="bc-products-list-pagination">
      <button :disabled="loadingPage" class="bc-button bc-button--neutral bc-button--full-width" type="button" v-show="showPagination" @click="next">
        {{ buttonText }}
      </button>
    </div>`
  })

  // Vue.component('products-search', {
  //   data: function () {
  //     return {
  //       q: null
  //     }
  //   },
  //   methods: {
  //     search: function () {
  //       store.state.q = this.q;
  //       window.productsListVm.$emit('getProducts')
  //     }
  //   },
  //   template: `<div>
  //     <input type="text" placeholder="Search Keyword/Product Code" v-model="q">
  //     <button type="button" @click="search">Go</button>
  //   </div>`
  // });

  window.productsListVm = new Vue({
    el: '#vue-products',
    data: productsVmState,
    mounted () {
      this.getProducts()
    },
    watch: {
      'pagination.perPage': function (newPagination) {
        this.getProducts(1)
      },
      'unit': function (newUnit) {
        Cookies.set('braiform.unit', newUnit)
      },
      'locale': function (newLocale) {
        Cookies.set('braiform.locale', newLocale)
      }
    },
    computed: {
      hasProducts: function () {
        return this.products.length > 0 && !this.loading
      }
    },
    methods: {
      serializeFacets () {
        let output = {}
        for (let facet of this.facets) {
          output[facet.key] = facet.options.filter(option => option.checked).map(option => option.title)
        }
        return output
      },
      getProducts (page) {
        let serializedFacets = this.serializeFacets()
        let querystring = []
        for (var key in serializedFacets) {
          let urlKey = S(key).underscore().s
          let component = `${urlKey}=`;
          let values = serializedFacets[key]
          if (values.length == 0) { continue }
          component += values.map(x => x.replace(' ', '+')).join(',')
          querystring.push(component)
        }
        if (this.q) {
          querystring.push(`q=${this.q}`)
        }
        querystring = encodeURI(querystring.join('&'))
        history.replace(`/catalogue/products?${querystring}`)

        let params = {
          q: this.q,
          per_page: this.pagination.perPage,
          facets: serializedFacets
        }

        if (page) {
          this.loadingPage = true
          params.page = page
        } else {
          this.loading = true
        }

        return axios.get('/catalogue/products.json', {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' })
          }
        }).then(response => {
          if (page) {
            this.products = this.products.concat(response.data.products)
          } else {
            this.products = response.data.products
          }
          this.pagination = response.data.pagination
          this.loading = false
          this.loadingPage = false
        }).catch((error) => {
          alert(error);
        })
      },
      clearFacets () {
        this.facets.map(facet => {
          return facet.options.map(option => {
            option.checked = false
            return option
          })
        })
      },
      clearSearch () {
        this.q = null
        this.getProducts()
      },
      paginateProducts (page) {
        this.page = page
        this.getProducts()
      },
      toggleFilters () {
        this.facetsIsOpen = !this.facetsIsOpen
      },
      seeAll () {
        this.clearFacets()
        this.clearSearch()
        this.getProducts()
      }
    }
  })

  window.productsListVm.$on('pageProducts', function (page) {
    this.getProducts(page)
  })

  window.productsListVm.$on('clearFacets', function () {
    this.clearFacets()
    this.getProducts()
  })

  window.productsListVm.$on('getProducts', function () {
    this.$data.pagination.currentPage = 1
    this.getProducts()
  })
})
